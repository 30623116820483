export default class PDFJs {
    init = (source, element) => {
       console.log("source",encodeURIComponent(source))
      // localStorage.setItem("file",source);
      const iframe = document.createElement('iframe');
        // iframe.src = `/pdfjs-2.9.359-dist/web/viewer.html?file=${encodeURIComponent(source)}`;
        iframe.src = `/pdfjs-2.9.359-dist/web/viewer.html`;

      //  iframe.src = `/pdfjs/web/viewer.html?file=${source}`;
      iframe.width = '100%';
      iframe.height = '100%';
  
      element.appendChild(iframe);
    }
  }