import Logo from './images/researchpad.png';
export let SERVER_URL = "https://www.researchpad.co/";
export const APP_NAME="ResearchPad";
export const LOGO=Logo;
export const FbLoginID=1604867696565153;
export const URL="https://mobile.researchpad.co/"
export const AppleClientId="co.novareader.researchpad"
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.researchpad.co/login"
export const AppVersion="0.5.1"
export const RedirectUri="https://www.researchpad.co/api/applelogin"


