import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdfURL, bookcover,articlepdfURL,serverurl } from '../../api/api';
import PDFViewer from '../../component/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';
import Header from '../../component/pageheader/pageHeader';
import { Spin, Col, Row,message,Progress} from 'antd';
import { useIndexedDB } from 'react-indexed-db';
import articalimg from '../../api/images/acover.png';
import {useLocation,useHistory } from 'react-router-dom'
import { LoadingOutlined,} from '@ant-design/icons';
import Axios from "axios";

const Pdf = (props) => { 
  const nlocation = useLocation();
  const contentid=nlocation.state.contentid
  const fuzzyid=nlocation.state.fuzzyid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const elasticid=props.match.params.elasticid
  const isonline=nlocation.state.isonline
  const type=nlocation.state.type
 const { add, getByIndex } = useIndexedDB('epub');
  const [location, setlocation] = useState('')
  const [loading, setloading] = useState(false)
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  const history = useHistory();
  
  
  useEffect(() => {
    console.log("hit")
    setloading(true)
    let name=titel;
    if(isonline=="yes"){
      name=titel+ "-pdf"
    }else{
      name=titel
    }
    getByIndex('name', name).then(file => {
      if (file) {
        const url = window.URL.createObjectURL(new Blob([file.file]));
        localStorage.setItem('pdfurl', url);
        setlocation(url)
        setloading(false)
      } else {
        const rpad_userCredentials = JSON.parse(localStorage.getItem('rpad_userCredentials'));
        getdata(rpad_userCredentials.apikey);
      }
    });
   }, [])

  const getdata = (apikey) => {
    message.success('Downloading ....');
    setloading(true)
    let url=pdfURL;
    if(type=="article"){
      url=articlepdfURL
    }
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
         setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
     Axios.get(url+elasticid, {responseType: "blob",headers: {'apikey': apikey,'Content-Type': "appl"}, ...options})
      .then(response => response.data)
      .then(file => {
        console.log("file",file)
            var imgurl=articalimg
            if(type=="book"){
              imgurl=bookcover + fuzzyid + "/cover.png"
            }
           
            add({ name: titel + "-pdf", file: file, img: imgurl, type: "pdf" }).then(
              event => {
                const url = URL.createObjectURL(new Blob([file]))
                localStorage.setItem('pdfurl', url);
                setlocation(url)
                setloading(false)
              },
              error => {
                setloading(false)
             }
            );
          })
           .catch(error => {
            message.error('Sorry, something went wrong try again !.');
            setTimeout(() => {
              history.goBack();
            }, 3000);
           });
  }

if (loading) {
    return (
      <div>
              <Header />
              <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center',padding:'10px' }}>
                  <div style={{ letterSpacing: '1px', marginTop: '10px',fontFamily:'var(--font)' }} dangerouslySetInnerHTML={{ __html:titel }}/>
                  <div style={{ height: '80%', width: '100%', display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {type=="book"?<img src={bookcover + fuzzyid + "/cover.png"} style={{ height: '100%', width: '94%', backgroundColor: '#cccccc',margin:'5px' }} alt="" loading="lazy" />:<img src={articalimg} style={{ height: '100%', width: '94%', backgroundColor: '#cccccc',margin:'5px' }} alt="" loading="lazy" />}
                  </div>
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff",flexDirection:'column',padding:'10px' }}>
                    <Progress percent={downloadInfo.progress} size="default" status="active"  />
                    <p style={{ color: '#000', letterSpacing: '1px', marginTop: '10px', marginLeft: '10px',fontFamily:'var(--font)' }}><span style={{ color: '#1890ff',}}> {formatBytes(downloadInfo.loaded)}</span> / {formatBytes(downloadInfo.total)}</p>
                  </div>
                </Col>
              </Row>
            </div>
    )
  }
  return (
    <PDFViewer
      backend={PDFJSBackend}
      src={location}
    />
  )
}

export default Pdf;