import React, {useState,useEffect} from "react";
import { Row, Col,Breadcrumb,message  } from 'antd';
import {bookcover,getbook,getJournals,serverurl} from '../../api/api'
import { useHistory } from 'react-router-dom'
import Header from '../../component/dashboard/header';
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import listbook from '../../api/images/emptybook.png'
import './styles.css';

const Booksbycategory=(props)=> {
    const history = useHistory();
    const [category,setcategory]= useState(props.match.params.category)
    const [books,setBooks]=useState([])
    const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])
    const [isLoading,setIsLoading]=useState(false)
    const [isMoreLoading,setIsMoreLoading]=useState(false)
    const [isHaveMoreData,setIsHaveMoreData]=useState(true)
    const [pagenation,setPagenation]=useState(0)

   
   
    useEffect(() => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        getBooksList()
     }, [])

     const getMathVal = () => {
        const mathval = Math.floor(Math.random() * 10);
        const myArray = ['url(' + serverurl + 'public/img/joucover/1.png)',
        'url(' + serverurl + 'public/img/joucover/2.png)',
        'url(' + serverurl + 'public/img/joucover/3.png)',
        'url(' + serverurl + 'public/img/joucover/4.png)',
        'url(' + serverurl + 'public/img/joucover/5.png)',
        'url(' + serverurl + 'public/img/joucover/6.png)',
        'url(' + serverurl + 'public/img/joucover/7.png)',
        'url(' + serverurl + 'public/img/joucover/8.png)',
        'url(' + serverurl + 'public/img/joucover/9.png)',
        ];
        const returnVal = myArray[mathval]
        return returnVal;
    }
    
   
const  getBooksList = async() => {
    console.log("pagenation",pagenation)
      let url=getbook+pagenation;
      console.log("cat",category)
      if(category=="newreleases"){
        setcategory("New Releases")
        url=getbook+pagenation
      }else if(category=="booksbyrating"){
        setcategory("Books By Rating")
          url=getbook+pagenation+"&rating=1"
      }else if(category=="openaccessbooks"){
        setcategory("Open Access Books")
         url=getbook+pagenation+"&access=1"
      }else if(category=="paidbook"){
        setcategory("Paid Book")
        url=getbook+pagenation+"&paid=1"
     }else if(category=="recentjournals"){
        setcategory("Recent Journals")
         url=getJournals+pagenation
     }else if(category=="journalsbyrating"){
        setcategory("Journals By Rating")
        url=getJournals+pagenation+"&rating=1"
     }
         var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(url, requestOptions)
          .then(response => response.json())
          .then(result =>{
               setBooks(prevTime=>prevTime.concat(result.data))
               setIsLoading(false)
               setIsMoreLoading(false)
               setPagenation(pagenation+20)
               if(result.data.length < 20){
                setIsHaveMoreData(false)

               }
          })
          .catch(error =>{ 
            setIsLoading(false)
            setIsMoreLoading(false)
            message.error('Sorry, something went wrong.');

           });
    }

    const loademore =() =>{
        setIsMoreLoading(true)
        getBooksList()
    } 
   
   
  
  if(isLoading){
    return (
        <div className="continer">
                   <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

        <Header titel={category} />
        <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{category}</Breadcrumb.Item>
             </Breadcrumb>
        <Row className="listcontiner">
       
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {emptyBooks.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                        <div  className="journalCover" style={{  backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                ))}
            </Row>
           </Col>
        </Row>
        </Col>
        </div>
    );
  }

  if(category=="Recent Journals"||category=="Journals By Rating"){
      return(
        <div className="continer">
                   <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

        <Header titel={category} />
        <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{category}</Breadcrumb.Item>
             </Breadcrumb>
      {books.length >0 ?  <Row className="listcontiner">
    
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {books.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                         <div onClick={() => history.push({ pathname:'/journal/'+item.uniqueName,state:{id:item.id,name:item.name,coverurl:getMathVal()}})}  className="journalCover" style={{ "backgroundImage": getMathVal(), backgroundColor:"#EEFBF9" }}>
                         <div className="pubName">{item.name}</div>

                         </div>
                    </Col>
                ))}
                 {isHaveMoreData ?<div className="moreloading">
                 {isMoreLoading?<div className="moreloading"> <LoadingOutlined style={{fontSize:'14px'}}/> <span style={{fontFamily:'var(--font)',fontSize:'15px',letterSpacing:'1px'}}>Loading...</span></div>:<div className="moreloading" onClick={loademore}> <span style={{fontFamily:'var(--font)',fontSize:'15px',letterSpacing:'1px'}}>Load More</span></div>}
                </div>:null}
            </Row>
           </Col>
         </Row>:
         <div className="nodata">
                 <img src={listbook} width={"100px"}/>
                   No journal found under the {category}  category,Sorry!         
                   </div>
         }
         </Col>
        </div>
      )

  }else{
   
        return (
            <div className="continer">
                       <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <Header titel={category} />
            <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{category}</Breadcrumb.Item>
             </Breadcrumb>
          {books.length >0 ?  <Row className="listcontiner">
        
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                <Row>
                    {books.map(item =>(
                         <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                         <div onClick={()=>{ history.push({pathname:'/book/isbn/'+ item.eisbn,state:{contentid:item.contentId,fuzzyid:item.fuzzyId}})}}  className="journalCover" style={{ backgroundImage: `url(${bookcover+item.fuzzyId+"/cover.png"})`, backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                    ))}
                     {isHaveMoreData ?<div className="moreloading">
                     {isMoreLoading?<div className="moreloading"> <LoadingOutlined style={{fontSize:'14px'}}/> <span style={{fontFamily:'var(--font)',fontSize:'15px',letterSpacing:'1px'}}>Loading...</span></div>:<div className="moreloading" onClick={loademore}> <span style={{fontFamily:'var(--font)',fontSize:'15px',letterSpacing:'1px'}}>Load More</span></div>}
                    </div>:null}
                </Row>
               </Col>
             </Row>:
             <div className="nodata">
                   <img src={listbook} width={"100px"}/>
                   No book found under the {category}  category,Sorry!
             </div>
             }
             </Col>
            </div>
        );
            }
    }


export default Booksbycategory;