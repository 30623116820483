import React from 'react'
import './styles.css';
import { useHistory } from 'react-router-dom'
const SubjectCard = (props) => {
    const history = useHistory();
    const [emptyData, setEmptyData] = React.useState([{}, {}, {}, {}, {}, {}, {}, {}])

    if (props.data.length === 0 && !props.isLoading) {
        return null
    }

    if (props.isLoading) {
        return (

            <div className="subject_continer">
                <div className="list_titel">
                    Explore Categories
                </div>
                <div className="subject_body">
                    {emptyData.map((item) => {
                        return (
                            <div className="subject_item" >
                               

                                <div className='listitem' style={{height:'35px',width:'100px'}}>
                                      </div>   

                            </div>
                        )
                    })}
                </div>

            </div>
        )
    }

    return (
        <div className="subject_continer">
            <div className="list_titel">
                Explore Categories
            </div>
            <div className="subject_body">
                {props.data.map((item) => {
                    return (
                        <div className="subject_item">
                            <div onClick={() => { history.push({ pathname: "/bookbysubject/" + item.uniqueName, state: { name: item.name } }) }} className="listitem">{item.name}</div>
                        </div>
                    )
                })}
            </div>

        </div>
    )

}
export default SubjectCard;