let SERVER_URL = 'https://test.researchpad.co/';
export const userLogin = SERVER_URL + "login";
export const contactUs=SERVER_URL + "sendemail";
export const userLoginv7 = SERVER_URL + "v7/userlogin?jsondata=";
export const resendMail = SERVER_URL+'resend';
export const getbook=SERVER_URL+'api/books?start=';
export const getbookinfo=SERVER_URL+'api/book/';
export const getJournals=SERVER_URL+'api/journals?start=';
export const getJournalinfo=SERVER_URL+'api/journal/';
export const getLibrary=SERVER_URL+'user/library/get';
export const signupURL = SERVER_URL+'v7/usersignup?jsondata=';
export const forgotURL = SERVER_URL+'forgotpassword';
export const googleLoginURL =  SERVER_URL + 'googlelogin?jsondata=';
export const orcIdURL = SERVER_URL + 'v7/orcidlogin?jsondata=';
export const getDocAuth = SERVER_URL + 'getdocsauth?jsondata=';
export const findUpdateorInsert = SERVER_URL + 'findupdateorinsert?jsondata=';
export const searchContent = SERVER_URL + 'api/search?';
export const passCodeVal = SERVER_URL + 'novapasscodevalidation';
export const faceBookLogin = SERVER_URL + '/facebooklogin?jsondata=';