import axios from "axios";


export default class APIProxy {

    authHeaders = {
      'token': '',
      'email': '',
      'userId': '',
      'userType': '',
      'teamId':1,
      'managerId':1,
      'apikey':'QmVhcmVyIGV5SmhiR2NpT2lKSVV6VXhNaUo5LmV5SnpkV0lpT2lKellYUm9lV0Z5WVdvdU1UZzVRR2R0WVdsc0xtTnZiU0lzSW1WNGNDSTZNVFl4TnpReU5qazBPQ3dpYVdGMElqb3hOakUwT0RNME9UUTRmUS5Xa0tvTHJKNjVySW1mTU5XM1NZNEZCZF81LWpyUmFnY0UwbzlwNTVJcGZFdmJKdld2U1hqNzY0NWN0TlgxYUVhMWhYblItbHZiVWhXYUZWaVotTDRDUQ==',
    };
    
  
    updateCredentialHeaders(credentials) {
      this.authHeaders = {
        'token': credentials.token,
        'email': credentials.email,
        'userId':credentials.userId,
        // 'userType':credentials.user_type,
        'userType':'admin',
        'teamId':1,
        'managerId':1
      }
    }
  
    
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'appkey':'nova',
      'apikey':'QmVhcmVyIGV5SmhiR2NpT2lKSVV6VXhNaUo5LmV5SnpkV0lpT2lKellYUm9lV0Z5WVdvdU1UZzVRR2R0WVdsc0xtTnZiU0lzSW1WNGNDSTZNVFl4TnpReU5qazBPQ3dpYVdGMElqb3hOakUwT0RNME9UUTRmUS5Xa0tvTHJKNjVySW1mTU5XM1NZNEZCZF81LWpyUmFnY0UwbzlwNTVJcGZFdmJKdld2U1hqNzY0NWN0TlgxYUVhMWhYblItbHZiVWhXYUZWaVotTDRDUQ==',
    }
 

  
   axiosPost=async(url)=> { 
    let res = '';
    await axios.post(url, '', {
        headers: this.headers
      })
      .then((response) => {
        res = response
      })
      .catch((error) => {
        console.log("error",error)
        alert(error);
      })
      return res;
    }
    axiosPostAgs=async(url,args)=> { 
      let res = '';
      await axios.post(url, args, {
          headers: this.headers
        })
        .then((response) => {
          res = response
        })
        .catch((error) => {
          alert('error');
        })
        return res;
      }
  }
  