import {ActionType} from '../contants/actions-type';
import store from '../store';
export const setSubject=(data)=>{
    return{
        type:ActionType.SET_SUBJECT,
        payload:data
    }
}

export const setNewBooks=(data)=>{
    return{
        type:ActionType.SET_NEWBOOKS,
        payload:data
    }

}
export const setBestRatingBooks=(data)=>{
    return{
        type:ActionType.SET_BESTRATINGBOOKS,
        payload:data
    }

}
export const setOpenAccessBooks=(data)=>{
    return{
        type:ActionType.SET_OPENACCESSGBOOKS,
        payload:data
    }

}
export const setNewJournals=(data)=>{
    return{
        type:ActionType.SET_NEWJOURNALS,
        payload:data
    }

}
export const setBestRatingJournals=(data)=>{
    return{
        type:ActionType.SET_BESTRATINGJOURNALS,
        payload:data
    }

}
export const setSelectedItem=(data)=>{
    return{
        type:ActionType.SET_SELECTEDITEM,
        payload:data
    }

}
export const setOfflinedata=(data)=>{
    return{
        type:ActionType.SET_OFFLINEDATA,
        payload:data
    }

}

export const setsubscription=(data)=>{
    return{
        type:ActionType.SET_SUBSCRIPTION,
        payload:data
    }

}
export const setsubscriptionloading=(data)=>{
    return{
        type:ActionType.SET_SUBSCRIPTIONFETCH,
        payload:data
    }

}
export const settheme=(data)=>{
    return{
        type:ActionType.SET_THEME,
        payload:data
    }

}
export const setbgcolor=(data)=>{
    return{
        type:ActionType.SET_BG,
        payload:data
    }

}
export const settab=(data)=>{
    return{
        type:ActionType.SET_TAB,
        payload:data
    }

}
export const sethometab=(data)=>{
    return{
        type:ActionType.SET_HOMETAB,
        payload:data
    }

}
export const settocopen=(data)=>{
    return{
        type:ActionType.SET_ISTOCOPEN,
        payload:data
    }

}
export const setreset=()=>{
        return{
            type:ActionType.SET_RESET,
       }
    
    }

export const fontaction=(value)=> {
    return (dispatch) => {
      let style = store.getState().App.epubstyle;
      let update = {
        fontsize:value
    }
  let result = Object.assign(style, update);
  dispatch(settheme(result));
    }
  }
  export const fontfamilyaction=(value)=> {
    return (dispatch) => {
      let style = store.getState().App.epubstyle;
      let update = {
        fontfamily:value
    }
  let result = Object.assign(style, update);
  dispatch(settheme(result));
    }
  }

  export const lineaction=(value)=> {
    return (dispatch) => {
      let style = store.getState().App.epubstyle;
      let update = {
        lineheight:value
    }
  let result = Object.assign(style, update);
  dispatch(settheme(result));
    }
  }
  export const setsearchitems=(data)=>{
    return{
        type:ActionType.SET_SEARCHBOOKS,
        payload:data
    }

}
  export const updateSeachbooks=(books,pagnation,searchtext)=>{
    return (dispatch) => {
        let style = store.getState().App.searchbooksvalues;
        let update = {
            books:books,
            searchtext:searchtext,
            pagination:pagnation
      }
    let result = Object.assign(style, update);
    dispatch(setsearchitems(result));
      }

}
export const setepublocation=(data)=>{
    return{
        type:ActionType.SET_EPUBLOCATION,
        payload:data
    }

}

export const updateepublocation=(chapter,display,totel)=>{
    return (dispatch) => {
        let style = store.getState().App.currentlocation;
        let update = {
            chapter:chapter,
            display:display,
            total:totel
      }
    let result = Object.assign(style, update);
    dispatch(setepublocation(result));
      }

}
  export const nightmodeaction=(color,bgcolor,filter)=> {
    return (dispatch) => {
      let style1 = store.getState().App.epubstyle;
      let update1 = {
        color:color,
        bgcolor:bgcolor,
        filter:filter
    }
  let result1 = Object.assign(style1, update1);
  dispatch(settheme(result1));
  let style2 = store.getState().App.readerArea;
  let update2 = {
    backgroundColor:bgcolor
}
let result2 = Object.assign(style2, update2);
dispatch(setbgcolor(result2));
    }
  }