import React, { useState } from "react";
import { Row, Col ,message,Breadcrumb} from 'antd';
import { bookcover, getbook,search } from '../../api/api'
import { useHistory } from 'react-router-dom'
import { LeftOutlined, ArrowLeftOutlined,LoadingOutlined, SearchOutlined, CloseOutlined,UserOutlined } from '@ant-design/icons';
import { updateSeachbooks } from '../../redux/actions/dashboardActions';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import articalimg from '../../api/images/acover.png';
import Header from '../../component/dashboard/header'

const Searchscreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isloading, setisloading] = useState(false)
    const [ismoreloading, setismoreloading] = useState(false)
    const [ismoredata, setismoredata] = useState(true)
    const searchbooksvalues = useSelector((state) => state.App.searchbooksvalues);
    const [text, settext] = useState(searchbooksvalues.searchtext)

    const getBooksList = async () => {
        let url = search;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(url+"cnt=1&q="+searchbooksvalues.searchtext+"&sort=recentold", requestOptions)
            .then(response => response.json())
            .then(result => {
                
                if(result.count==0){
                    setismoredata(false)
                    message.warning('No Data Found ');

                }else{
                    dispatch(updateSeachbooks(searchbooksvalues.books.concat(result.data), searchbooksvalues.pagination + 20, text))

                }
                setisloading(false)
                setismoreloading(false)

            })
            .catch(error => {
                setisloading(false)
                setismoreloading(false)
            });
    }

    const onSearch = () => {
        if (text) {
            dispatch(updateSeachbooks([], 0, text))
            setismoredata(true)
            setisloading(true)
            getBooksList()
        }
    }

    const onClear = () => {
        settext("")
        dispatch(updateSeachbooks([], 0, ""))

    }

    const loademore = () => {
        setismoreloading(true)
        getBooksList()
    }

    const updatetext = event => {
        // if (event.target.value.length == 0) {
        //     settext("")
        //     dispatch(updateSeachbooks([], 0, ""))
        // }
        settext(event.target.value)

    }

    const  goToreader=(item)=>{
        history.push('/pdf/' + item.contentId + "/" + "000" + "/" + item.name.replace(/<\/?[^>]+(>|$)/g, "") + "/" + item.elasticId + "/" + "yes"+"/"+"article")
    
    
       }
       const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
    const handleAnswerChange=(event)=>{
        message.destroy()

console.log(event.key)
if (event.key=="Enter") {
    if (text.length==0) {
        message.warning('Search box is Empty');

    }else{
        dispatch(updateSeachbooks([], 0, text))
        setismoredata(true)
        setisloading(true)
        getBooksList()
    }

}


}

    return (
    
        <div className="searchscreen" >
                        <Header/>
                        <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">Search</Breadcrumb.Item>
             </Breadcrumb>
            <div className="searchheader">
                {/* <div onClick={() => { history.goBack() }} className="gobackicon">
                    <LeftOutlined />
                </div> */}
                <div className="searchinput">
                    <div className='searchbox'>
                        <input type="search" onKeyPress={handleAnswerChange} className="searchboxfiled" placeholder="Search by Title,Author,DOI ..." name="search" value={text} onChange={updatetext} />
                      {text &&  <CloseOutlined onClick={onClear} className='clearicon' />}
                        {isloading ? <div className='searchicon'> <LoadingOutlined onClick={onSearch} /></div> : <SearchOutlined onClick={onSearch} className='searchicon' />}
                    </div>
                </div>
            </div>
            {searchbooksvalues.books.length > 0 ? <Row className="booklist">
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 1 }}>
                    <Row>
                        {searchbooksvalues.books.map(item => {
                            let authors=" ";
                            const hasKey = item.hasOwnProperty('authors');
                            if(hasKey) {
                                 authors=item.authors.map(item=>item.name)
                            }
                             if(item.type === "Article"){
                                 if(item.name && item.elasticId){
                                     console.log(item.elasticId,item.name,item.articleTypes,authors,item.doi)
                                return(
                                    <div onClick={()=>{history.push({pathname:'/article/'+item.elasticId,state:{name:item.name,jname:item.articleTypes,author:authors,doi:item.doi}})}} style={{width:'100%',display:'flex', background: '#ffffff', boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",marginTop:'10px',marginLeft:'5px',marginRight:'5px'}}>
                                   <div style={{width:'75%',display:'flex',overflow:"hidden",paddingTop:'10px',paddingLeft:'5px',letterSpacing:'1px',fontWeight:'600',fontSize:'15px',flexDirection:'column',}}>
                                   <div className="articlename"  dangerouslySetInnerHTML={{ __html: add3Dots(item.name,60) }}/>
                                   <span  className="articledoi"><span style={{marginRight:'5px',color:'#ff7600'}}>DOI</span>{": "+item.doi}</span> 
                               {hasKey? <span className="articleauthorname"> <UserOutlined style={{marginRight:'5px',color:'#ff7600'}}/>{add3Dots(authors.toString().replaceAll(",",", "),40)}</span>:null }

                                </div>
                            <div style={{width:'25%',height:'auto',display:'flex',alignItems:'center',justifyContent:'center'}}>
                              <img style={{ width: '100%', height:'100px',margin:'10px' }} src={articalimg} alt="" />
                            </div>
                                </div>
                               
                                )}else{
                                    return null
                                }
                            }
                            else if(item.type === "Book"){
                                return(
                                    <div onClick={() => { history.push({ pathname: '/book/isbn/' + item.isbn, state: { contentid: item.contentId } }) }} style={{width:'100%',display:'flex', background: '#ffffff', boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",marginTop:'10px',marginLeft:'5px',marginRight:'5px'}}>
                                    <div style={{width:'75%',display:'flex',overflow:"hidden",paddingTop:'10px',paddingLeft:'5px',letterSpacing:'1px',fontWeight:'600',fontSize:'15px',flexDirection:'column',}}>
                                    <div className="articlename"  dangerouslySetInnerHTML={{ __html: add3Dots(item.name,60) }}/>
                                    <span  className="articledoi"><span style={{marginRight:'5px',color:'#ff7600'}}>ISBN</span>{": "+item.isbn}</span> 
                                {hasKey? <span className="articleauthorname"> <UserOutlined style={{marginRight:'5px',color:'#ff7600'}}/>{add3Dots(authors.toString().replaceAll(",",", "),40)}</span>:null }
 
                                 </div>
                             <div style={{width:'25%',height:'auto',display:'flex',alignItems:'center',justifyContent:'center'}}>
                               <img style={{ width: '100%', height:'100px',margin:'10px' }} src={bookcover + item.contentId + "/cover.png"} alt="" />
                             </div>
                                 </div>
                                
                                )}else{
                                    return null
                                }
               })}
                       
                    </Row>

                </Col>
            </Row> :
                <div className="nodata">
                    Search by Title,Author,DOI,Series,Collection,Publisher or subject
                </div>}
        </div>
        
    );
}
//
//
export default Searchscreen;