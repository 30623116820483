import SkeletonElament from './skeletonelament';
import Shimmer from './shimmer';


const skeletonarticle=()=>{
    
    return(
        <>
        {[1,2,3,4].map((item)=>{
            return(
                <div style={{width:'100%',display:'flex', background: '#ffffff', boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",marginTop:'10px',position:'relative'}}>
               <>
                <div style={{width:'75%',display:'flex',overflow:"hidden",paddingTop:'10px',paddingLeft:'5px',letterSpacing:'1px',fontWeight:'600',fontSize:'15px',flexDirection:'column',}}>
                <SkeletonElament type="titel"/>
                <SkeletonElament type="sub1"/>
                <SkeletonElament type="sub2"/>
               </div>
               <div style={{width:'25%',height:'auto',display:'flex',alignItems:'center',justifyContent:'center'}}>
               <SkeletonElament type="cover"/>
                            </div>
                <Shimmer/>
            
                </>
    </div>
            )
        })}
        
    </>
    )
}

export default skeletonarticle;