import React from "react";
import {Col,Result,Button} from 'antd';
import {useHistory} from 'react-router-dom'
import Header from '../component/dashboard/header';

const PageNotFound = (props) => {
    const history = useHistory();

    return(
        <div className="continer" >
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
         <Header/>
         <Result
               status="404"
                title="404"
                 subTitle="Sorry, the page you visited does not exist."
                extra={<Button onClick={()=>{history.replace("/");}}  type="primary">Back Home</Button>}
              />
            </Col>
            </div>
    )
}


export default PageNotFound;
