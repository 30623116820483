import SkeletonElament from './skeletonelament';
import Shimmer from './shimmer';


const articlelanding=()=>{
    
    return(
        <>
        {[1].map((item)=>{
            return(
                <div style={{width:'100%',display:'flex', background: '#ffffff',marginTop:'10px',position:'relative'}}>
               <>
                <div style={{width:'100%',display:'flex',overflow:"hidden",letterSpacing:'1px',fontWeight:'600',fontSize:'15px',flexDirection:'column',}}>
                <SkeletonElament type="articletitel"/>
                <SkeletonElament type="line1"/>
                <SkeletonElament type="line2"/>
                <SkeletonElament type="line3"/>
                <SkeletonElament type="line3"/>
                <SkeletonElament type="line4"/>
                <SkeletonElament type="line4"/>

               </div>
              
                <Shimmer/>
            
                </>
    </div>
            )
        })}
        
    </>
    )
}

export default articlelanding;