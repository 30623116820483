import React from 'react';
import { useSwipeable } from 'react-swipeable';

const Swaip=()=>{
    let handlers = useSwipeable({
        onSwiped: (eventData) => console.log("User Swiped!", eventData),
      });
      return handlers;
     
}

export default Swaip;