import React, {useState,useEffect} from "react";
import { Row, Col, Breadcrumb,message } from 'antd';
import {bookcover,subject} from '../../api/api'
import { useHistory,useLocation} from 'react-router-dom'
import './styles.css';
import Header from '../../component/dashboard/header'
import listbook from '../../api/images/emptybook.png'
import articalimg from '../../api/images/acover.png';
import {LoadingOutlined,UserOutlined,ArrowLeftOutlined} from '@ant-design/icons';

const Booksbysubject=(props)=> {
    const history = useHistory();
    const location = useLocation();
    const name = location.state.name
    const [books,setBooks]=useState([])
    const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])
    const subjectid=props.match.params.subject
    const [isLoading,setIsLoading]=useState(false)
    const [isMoreLoading,setIsMoreLoading]=useState(false)
    const [isHaveMoreData,setIsHaveMoreData]=useState(true)
    const [pagenation,setPagenation]=useState(0)
    useEffect(() => {
        window.scrollTo(0, 0)
         setIsLoading(true)
         getBooksList()
     }, [])
   
   
  const  getBooksList = async() => {
         var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(subject+subjectid+"/api"+"?"+"start="+pagenation, requestOptions)
          .then(response => response.json())
          .then(result =>{
               setBooks(prevTime=>prevTime.concat(result.data))
               setIsLoading(false)
               setIsMoreLoading(false)
               setPagenation(pagenation+20)
               if(result.data.length < 20){
                setIsHaveMoreData(false)

               }
          })
          .catch(error =>{
            setIsLoading(false)
            setIsMoreLoading(false)
            message.error('Sorry, something went wrong.');

          });
    }
    const add3Dots = (string, limit) => {
        var dots = " ...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
    const loademore =() =>{
        setIsMoreLoading(true)
        getBooksList()
    } 

   
   
  
  if(isLoading){
    return (
        <div className="continer">
         <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

        <Header titel={name} />
        <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{name}</Breadcrumb.Item>
             </Breadcrumb>
        <Row className="listcontiner">

        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {emptyBooks.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                        <div  className="journalCover" style={{  backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                ))}
            </Row>
           </Col>
        </Row>
        </Col>
        </div>
    );
  }

 const  goToreader=(item)=>{
    history.push('/pdf/' + item.contentId + "/" + "000" + "/" + item.name.replace(/<\/?[^>]+(>|$)/g, "") + "/" + item.elasticId + "/" + "yes"+"/"+"article")


   }
        return (
            <div className="continer">
                         <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <Header titel={name} />
            <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{name}</Breadcrumb.Item>
             </Breadcrumb>
          {books.length >0 ?  <Row className="listcontiner">

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                 <Row>
                    {books.map(item =>{
                        let authors=" ";
                        const hasKey = item.hasOwnProperty('authors');
                        if(hasKey) {
                             authors=item.authors.map(item=>item.name)
                            console.log(authors)                        
                        }
                        
                        if(item.type==="Article"){
                            return(
                                <div onClick={()=>{history.push({pathname:'/article/'+item.elasticId,state:{name:item.name,jname:name,author:authors,doi:item.doi}})}} style={{width:'100%',display:'flex', background: '#ffffff', boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",marginTop:'10px',marginLeft:'5px',marginRight:'5px'}}>
                                   <div style={{width:'75%',display:'flex',overflow:"hidden",paddingTop:'10px',paddingLeft:'5px',letterSpacing:'1px',fontWeight:'600',fontSize:'15px',flexDirection:'column',}}>
                                   <div className="articlename"  dangerouslySetInnerHTML={{ __html: add3Dots(item.name,60) }}/>
                                   <span  className="articledoi"><span style={{marginRight:'5px',color:'#ff7600'}}>DOI</span>{": "+item.doi}</span> 
                               {hasKey? <span className="articleauthorname"> <UserOutlined style={{marginRight:'5px',color:'#ff7600'}}/>{add3Dots(authors.toString().replaceAll(",",", "),40)}</span>:null }

                                </div>
                            <div style={{width:'25%',height:'auto',display:'flex',alignItems:'center',justifyContent:'center'}}>
                              <img style={{ width: '100%', height:'100px',margin:'10px' }} src={articalimg} alt="" />
                            </div>
                                </div>
                           
                            )
                        }else{
                            return(
                        <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                            <div onClick={()=>{history.push({pathname:'/book/isbn/'+item.isbn,state:{contentid:item.contentId}})}}  className="journalCover" style={{ backgroundImage: `url(${bookcover+item.contentId+"/cover.png"})`, backgroundColor:"#EEFBF9" }}>
                              
                            </div>
                        </Col>
                             ) }
                })}
                 {isHaveMoreData ?<div className="moreloading">
                 {isMoreLoading?<div className="moreloading"> <LoadingOutlined style={{fontSize:'14px'}}/> <span style={{fontFamily:'var(--font)',fontSize:'15px',letterSpacing:'1px'}}>Loading...</span></div>:<div className="moreloading" onClick={loademore}> <span style={{fontFamily:'var(--font)',fontSize:'15px',letterSpacing:'1px'}}>Load More</span></div>}
                </div>:null}
                </Row>
               
            </Col>
            </Row>:
             <div className="nodata">
                 <img src={listbook} width={"100px"}/>
                 No article found under the {subjectid}  category,Sorry!
            </div>
            }
            </Col>
            </div>
        );
    }


export default Booksbysubject;