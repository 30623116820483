import React, {Component} from "react";
import { Form, Input, Button, Checkbox, Empty, Modal,message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { forgotURL } from '../../api/api';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class NormalForgotPasswordForm extends Component {
  constructor(){
      super();
      this.state={
        forgotVar:true, 
        verfiyVar:false,
        loadings:false,
        btnText:"Reset Password",
      }
  }
  onFinish = async(values) => {
    this.setState({
      loadings:true,
      btnText:"Loading"
    })
   
    console.log('Success:', values);
  
    var formdata = new FormData();
    formdata.append("email", values.email);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    fetch(forgotURL, requestOptions)
    .then(response => response.json())
    .then(result =>{ 
    console.log(result)
    if (result.r === 1) {
      this.setState({
        loadings:false,
        btnText:"Reset Password"
      })
     
      this.setState({forgotVar:false, verfiyVar:true});
    }
    else if(result.r === 0){
      this.setState({
        loadings:false,
        btnText:"Reset Password"
      })
      Modal.error({
        title: 'Forgot Password',
        content: 'Your email address is not registered. Please register with us.',
      });
    }
    else
    {
      this.setState({
        loadings:false,
        btnText:"Reset Password"
      })
      Modal.error({
        title: 'Forgot Password',
        content: 'Oops error occurred!...',
      });
    }
  })
  .catch(error =>{ 
    this.setState({
      loadings:false,
      btnText:"Reset Password"
    })
    Modal.error({
      title: 'Forgot Password',
      content: 'Oops error occurred!...',
    })
    console.log('error', error)});
  ;
  };



  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
render(){
  const {forgotVar, verfiyVar} = this.state;
  return (
    <div>
      {forgotVar == true ? 
      <Form  name="basic"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          // label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
           <Input placeholder="Email" style={{fontFamily:'var(--font)'}}  />
           
        </Form.Item>
       
          
        <Form.Item style={{"clear":"both",marginTop:'30px'}}  >
          <Button className="loginBtn" type="primary"  htmlType="submit" block loading={this.state.loadings}>
        <span className="btntext">  {this.state.btnText}</span>
          </Button>
        </Form.Item>
      </Form>:""}
    {verfiyVar == true ?
    <Empty
          description={
            <span style={{marginBottom:"50px"}}>
              <span><b>Forgot Password</b></span>
              <br/>
              Email was sent with an reset password link.
            </span>
          }
        >
          {/* <Button type="primary">Create Now</Button> */}
    </Empty>:""}
    </div>
  );
}
};

export default NormalForgotPasswordForm;