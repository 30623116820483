import React, { useEffect, useState } from 'react'
import List from './page/book&journallist/book_journals_List';
import Reader from './page/Reader';
import Login from './page/login/LoginScreen';
import Home from './page/dashboard/home';
import Pdf from './page/pdf/pdf';
import NewDescription from './page/description/newdescription';
import Artical from './page/description/artical';
import Offline from './page/offline';
import Acsetting from './page/setting/account';
import Bookbysubject from './page/book/booksbysubject';
import Bookbycategory from './page/book/booksbycategory';
import Journal from './page/description/journal';
import Resetpassword from './page/login/resetpassword';
import Search from './page/search/search';
import "antd/dist/antd.css";
import './App.css';
import { DBConfig } from './DBConfig';
import { initDB } from 'react-indexed-db';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import { appName } from './api/api';
import { message} from 'antd';
import Header from '../src/component/dashboard/header'
import PageNotFound from './component/PageNotFound';

initDB(DBConfig);

function App() {
  const[isOnLine,setIsOnLine]=useState("online")
  window.addEventListener("load", () => {
    navigator.onLine
      ? console.log("You are online")
      :console.log("You are offline");
       window.addEventListener("online", () => {
       console.log("You are online back !!")
       message.success('You are online back !!');
    });
    window.addEventListener("offline", () => {
      if(isOnLine === "online" ){
        setIsOnLine("offline")
        message.warning('You are offline !!');
     }
   });
  });
   
  useEffect(() => {
    document.title = appName;
  },[])

  return (
    
    <Router>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/login" component={Login}/>
      <Route exact path="/resetpassword" component={Resetpassword}/>
      <Route exact path="/list" component={List}/>
      <Route exact path="/read/book/epub/:elasticid" component={Reader}/>
      <Route exact path="/read/book/pdf/:elasticid" component={Pdf}/>
      <Route exact path="/book/isbn/:isbn/" component={NewDescription}/>
      <Route exact path="/bookbysubject/:subject/" component={Bookbysubject}/>
      <Route exact path="/bookbycategory/:category/" component={Bookbycategory}/>
      <Route exact path="/journal/:category" component={Journal}/>
      <Route exact path="/offline" component={Offline}/>
      <Route exact path="/setting" component={Acsetting}/>
      <Route exact path="/search" component={Search}/>
      <Route exact path="/article/:id" component={Artical}/>
      <Route path="*" component={PageNotFound} />

    </Switch>
    
  </Router>
  
  );
}

export default App;
