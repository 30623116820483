import React, { useState, useEffect } from "react";
import { Row, Col, Breadcrumb, message } from 'antd';
import { getJournalinfo, } from '../../api/api'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../../component/dashboard/header';
import { LoadingOutlined, UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Skeletonarticle from '../../component/skeleton/skeletonarticle';
import articalimg from '../../api/images/acover.png';
import listbook from '../../api/images/emptybook.png';
import './styles.css';

const Journal = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [articals, setarticals] = useState([])
    const [jurnalinfo, setjurnalinfo] = useState()
    const [loading, setloading] = useState(true)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const [isHaveMoreData, setIsHaveMoreData] = useState(false)
    const [pagenation, setPagenation] = useState(0)
    const [description, setdescription] = useState(null)
    const category = location.state.name
    const coverurl = location.state.coverurl
    const name = props.match.params.category
    const id = location.state.id


    useEffect(() => {
        window.scrollTo(0, 0)
        getJournalList()
    }, [])

    const getJournalList = async () => {
        let url = getJournalinfo + id + "?" + "start=" + pagenation;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                setarticals(prevTime => prevTime.concat(result.article))
                setdescription(result.data.description)
                setjurnalinfo(result.data)
                setloading(false)
                setIsMoreLoading(false)
                setPagenation(pagenation + 20)
                if (result.article.length < 20) {
                    setIsHaveMoreData(false)
                } else {
                    setIsHaveMoreData(true)
                }
            })
            .catch(error => {
                setloading(false)
                setIsMoreLoading(false)
                message.error('Sorry, something went wrong.');
            });
    }




    const loademore = () => {
        setIsMoreLoading(true)
        getJournalList()
    }

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }


    return (
        <div className="continer">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                <Header titel={category} />
                <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                    <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                    <Breadcrumb.Item className="pageheadertitle">Journal</Breadcrumb.Item>
                </Breadcrumb>
                <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '10px', borderRadius: "5px" }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', padding: '10px', backgroundColor: "#EEF9FB", height: description ? 'auto' : '190px', borderRadius: "5px", }}>
                            <div className="journaimg" style={{ float: 'left', height: '170px', width: '140px', backgroundImage: coverurl, backgroundColor: "#fff", backgroundSize: '100%', marginRight: '10px', borderRadius: "5px", }}><div className="pubName">{category}</div></div>
                            {description ? <div className="articledesc"><span className="articledescheade">About this Journal:<br></br></span>{description}</div> : null}
                        </div>
                        <div className="articlelistheader">Latest articles</div>
                        {loading ? <Skeletonarticle /> : articals.length > 0 ? articals.map(item => {
                            let authors = " "
                            const hasKey = item.hasOwnProperty('authors');
                            if (hasKey) {
                                authors = item.authors.map(item => item.name)
                            }

                            return (
                                <div onClick={() => {
                                    history.push({ pathname: '/article/' + item.elasticId, state: { name: item.name, jname: category, author: authors, doi: item.doi } })
                                }} style={{ width: '100%', display: 'flex', background: '#ffffff', boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)", marginTop: '10px', }}>
                                    <div style={{ width: '75%', display: 'flex', overflow: "hidden", paddingTop: '10px', paddingLeft: '5px', letterSpacing: '1px', fontWeight: '600', fontSize: '15px', flexDirection: 'column', }}>
                                        <div className="articlename" dangerouslySetInnerHTML={{ __html: add3Dots(item.name, 60) }} />
                                        <span className="articledoi"><span style={{ marginRight: '5px', color: '#ff7600' }}>DOI</span>{": " + item.doi}</span>
                                        {hasKey ? <span className="articleauthorname"> <UserOutlined style={{ marginRight: '5px', color: '#ff7600' }} />{add3Dots(authors.toString().replaceAll(",", ", "), 40)}</span> : null}
                                    </div>
                                    <div style={{ width: '25%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img style={{ width: '100%', height: '100px', margin: '10px' }} src={articalimg} alt="" />
                                    </div>
                                </div>
                            )
                        }) : <div className="nodata">
                            <img src={listbook} width={"100px"} />
                            No article found under the {category}  category,Sorry!
                        </div>}
                        {isHaveMoreData ? <div className="moreloading">
                            {isMoreLoading ? <div className="moreloading"> <LoadingOutlined style={{ fontSize: '14px' }} /> <span style={{ fontFamily: 'var(--font)', fontSize: '15px', letterSpacing: '1px' }}>Loading...</span></div> : <div className="moreloading" onClick={loademore}> <span style={{ fontFamily: 'var(--font)', fontSize: '15px', letterSpacing: '1px' }}>Load More</span></div>}
                        </div> : null}
                    </div>
                </Row>
            </Col>
        </div>
    );
}


export default Journal;



