import React from 'react';
import {ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import './styles.css'
import {SearchOutlined} from '@ant-design/icons';

const PageHader=(props)=>{
    const history = useHistory();

    return(
        <div className="headerbar">
        <div onClick={()=>{history.goBack()}}  className="gobackicon">
        <ArrowLeftOutlined />
        </div>
        <div className="bartitel">
              {props.titel}
        </div>
        <div className="menucontiner">
      {/* <div className="profile">
          <SearchOutlined  onClick={() => history.push('/search')} className="menuicon"/>
          </div> */}
      
       
      </div>
    </div>
    )
}

export default PageHader;