import React, { useState, useEffect,useRef } from "react";
import {Col,Popover, message,Typography,Breadcrumb,Result,Button,BackTop} from 'antd';
import {articaldetails, favoriteURL, bookpath, saveandremovefav,serverurl } from '../../api/api'
import {useHistory, useLocation } from 'react-router-dom'
import Header from '../../component/dashboard/header';
import { AlignCenterOutlined,ArrowLeftOutlined, UserOutlined, HeartOutlined, FilePdfOutlined, HeartFilled, LoadingOutlined,ShareAltOutlined,ArrowUpOutlined} from '@ant-design/icons';
import Skeletonarticle from '../../component/skeleton/articlelanding';
import { RWebShare } from "react-web-share";
import './styles.css';
const { Title } = Typography;
const Artical = (props) => {
    const listInnerRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [isFavorite,setisFavorite]=useState(false)
    const [showpageheader,setshowpageheader]=useState(true)
    const [visible, setVisible] = useState(false);
    const [article, updateArticle] = useState({ 
        name:location.state.name==null?" ":location.state.name, 
        journalName:location.state.jname==null?" ":location.state.jname,
        volume:0,
        issue:0,
        doi:' ',
        elasticId:props.match.params.id,
        contentId:' ',
        contentTypeId:' ',
        isbn:' ',
        authors:location.state.author,
        isFavorite:false,
        keywords:[],
        abstractView:' ',
        htmlView:' ',
        toc:[],
        access:0,
        isHavePdf:false,
        isHaveEpub:false,
        isHaveHtmlView:false,
        publisherName:" ",
        License:" "
   })
    const [loadings, setloadings] = useState(false);
    const [isFetchingArticle,setIsFetchingArticle] = useState(true)
    const [error,seterror]=useState(false)
    const rpad_userCredentials = JSON.parse(localStorage.getItem('rpad_userCredentials'));
    const script=`<script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script> <script id="MathJax-script" async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"></script>`

    useEffect(async () => {
        window.scrollTo(0, 0)
        if (rpad_userCredentials != null) {
            if (rpad_userCredentials.emailVerified != 1) {
                history.push('./login');
            } else {
                getArticalinfo(rpad_userCredentials.apikey)
                getFavorite(rpad_userCredentials.apikey)            }
        }
        else {
            history.push('./login');
        }
      
     }, [])



    const getFavorite = async (apikey) => {
        let axios = require('axios');
        let config = {
            method: 'get',
            url: favoriteURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
        .then((response) => {
                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj => obj.elasticId === article.elasticId);
                    if (isValuefound.length > 0) {
                        setisFavorite(true)
                    } else {
                        setisFavorite(false)

                    }
                 } else {

                    setisFavorite(false)


                }

            })
            .catch(function (error) {
                message.error('Sorry, something went wrong.');

            });
    }




    const getArticalinfo = async (apikey) => {
        var requestOptions = {method: 'GET',redirect: 'follow', headers: {
            'apikey': apikey
        }};
         fetch(articaldetails + article.elasticId, requestOptions)
            .then(response => response.json())
            .then(result => {
                let htmlview = ""
                let abstractview = ""
                let getauthors = result.article.authorlist.map(item => item.name)
                let getkeywords = result.article.kwd.map(item => item.name)
                if (result.article.html) {
                    htmlview = result.article.xhtml.replaceAll('src="', 'src="https://researchpad.co')

                }
                if (result.article.abstractContent) {
                    abstractview = result.article.abstractContent.replaceAll('src="', 'src="https://researchpad.co')

                }
                updateArticle({
                    ...article,
                    name:result.article.title,
                    journalName:result.article.journal,
                    volume:result.article.volume,
                    issue:result.article.issue ,
                    abstractView:abstractview,
                    htmlView:htmlview,
                    authors:getauthors,
                    keywords:getkeywords,
                    contentId:result.contentAccess.contentId,
                    isbn:result.isbn,
                    doi:result.article.doi,
                    isHavePdf:result.article.pdfExists,
                    contentTypeId:result.content.contentTypeId,
                    access:result.article.access,
                    publisherName:result.article.publisherName,
                    License:result.article.license,
                    toc:result.article.toc})
                   setIsFetchingArticle(false)

            })
            .catch(error => {
                setIsFetchingArticle(false)
                seterror(true)
                console.log('error', error)
            });
    }
    const addOrRemoveLibrary = (id) => {
        setloadings(true);
        console.log(article.isFavorite)
        var myHeaders = new Headers();
        myHeaders.append("apikey", rpad_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("contentId", article.contentId);
        formdata.append("library", id);
        formdata.append("path", bookpath + article.isbn);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(saveandremovefav, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (id == 1) {
                    message.success('Article added to your favorite list');
                    setisFavorite(true)
                    setloadings(false);
                } else {
                    message.success('Article Removed from your favorite list');
                    setisFavorite(false)
                    setloadings(false);

                }
            })
            .catch(error => {
                message.error('Sorry, something went wrong.');
                setloadings(false);

            });
    }

   

    const goToReader = () => {
        if (article.access == 1) {
            if (article.isHavePdf == true) {
                history.push({ pathname: '/read/book/pdf/' + article.elasticId, state: { contentid: article.contentTypeId , isbn: "000", name: article.name, type: "article",isonline:"yes" } })

            }
        }
    }

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }

        return string;
    }

    const tocContent = () => {
        return (
            <>
                {article.toc.map((item) => {
                    return <div style={{ color: '#ff7600',fontFamily:'var(--font)',padding:'7px'}} onClick={() => { document.getElementById(item.secId).scrollIntoView();hide() }}>{item.secTitle}</div>
                })}
            </>
        )
    }
    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        let text = targetLink.href;
        let position = text.search(article.elasticId);
        console.log(position)
        console.log(targetLink.href)

        if (position >= 0) {

            var link = targetLink.href;
            var getid = link.split('#', 2);
            var sectionid = getid[1]
            document.getElementById(sectionid).scrollIntoView()
        } else {
            window.open(targetLink.href);

            console.log(targetLink.href)

        }

    };

    

    const onScroll = () => {
        var offsetHeight = document.getElementById('articlehead1').offsetHeight+document.getElementById('articlehead2').offsetHeight;
       if (listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop >= offsetHeight) {
            setshowpageheader(false)
         }else{
            setshowpageheader(true)

          }
        }
      };

      const hide = () => {
        setVisible(false);
      };
    
      const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
      };
    
      const goTop=()=>{
        document.getElementById("articlehead1").scrollIntoView()
    }

   




 
if(error){
    return(
        <div className="continer"  >
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

           <Header/>
          <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
              <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
              <Breadcrumb.Item className="pageheadertitle">Article</Breadcrumb.Item>
            </Breadcrumb>
            <Result
               status="500"
                title="500"
                 subTitle="Sorry, something went wrong."
                extra={<Button onClick={()=>{history.goBack()}}  type="primary">Back</Button>}
              />
            </Col>
            </div>
    )

  
}
   


    return (
        <div className="continer"   >

        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

           <Header   />
           {showpageheader? <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
              <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
              <Breadcrumb.Item className="pageheadertitle">Article</Breadcrumb.Item>
            </Breadcrumb>:<div  style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px',marginRight:'10px',flexDirection:'row',display:'flex',height:'40px',alignItems:'center',}} >
                   <Popover style={{fontFamily:'var(--font)',marginLeft:'5px'}} overlayInnerStyle={{ background: "#DBDCDD",fontFamily:'var(--font)' ,marginLeft:'10px'}} content={tocContent} title={<Title  style={{fontFamily:'var(--font)',fontSize:'14px',letterSpacing:'1px',fontWeight:'normal'}}>Table of Content</Title>} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}  >
                     <AlignCenterOutlined style={{ color: '#ff7600', marginRight: '3px', }} />
                   </Popover>   
                   <div className="articleheadername" dangerouslySetInnerHTML={{ __html: article.name }}/>
                   {article.isHavePdf ? <div onClick={goToReader} style={{ fontSize: '13px', color: '#fff', padding: '5px', backgroundColor: '#ff7600', fontWeight: 'bold', letterSpacing: '1px', borderRadius: "5px",fontFamily:'var(--font)',display:'flex',alignItems:'center',justifyContent:'space-between'}}> <FilePdfOutlined style={{marginRight:'3px'}}/> PDF </div> : null}

                    </div>
                    
                    }

           <div  className="articlecontiner" style={{padding:'7px'}} onScroll={() => onScroll()} ref={listInnerRef}>
               <div id="articlehead1" style={{ marginRight: '10px', padding: '5px' }}>
                   <div style={{ textAlign: 'center', fontSize: '16px', letterSpacing: '1px', fontFamily:'var(--font)',color:'#f36c23',fontWeight:'bold' }}>{article.journalName.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                   <div style={{ textAlign: 'center', fontSize: '14px', letterSpacing: '1px', fontFamily:'var(--font)',color:'#273746' }}>{article.publisherName.replace(/<\/?[^>]+(>|$)/g, "")}</div>

                   <div style={{  textAlign: 'center' ,fontFamily:'var(--font)',fontSize: '12px',letterSpacing:'1px',color:'#808080'}}><span style={{color:'#ff7600',fontSize: '12px',fontFamily:'var(--font)',letterSpacing:'1px'}}>Volume:</span>{article.volume} <span style={{color:'#ff7600',fontSize: '12px',fontFamily:'var(--font)',letterSpacing:'1px'}}>Issue:</span>{article.issue}</div>
                   <div style={{ height: '1px', width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#E5E5E7', }}></div>
                   <div style={{ display: 'flex', flexDirection: 'row' }}>
                       <div style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '1px',fontFamily:'var(--font)' ,color:'#000',fontWeight:'bold'}} dangerouslySetInnerHTML={{ __html: article.name }}/>
                   </div>
                   <div style={{ fontSize: '13px', color: '#808080',fontFamily:'var(--font)' }}> <UserOutlined style={{marginRight:'5px',color:'#ff7600'}} />{article.authors.toString().replaceAll(",", ", ")}</div>
               </div>
              {isFetchingArticle?<div style={{ marginRight: '10px', padding: '5px' }}>
                   <div style={{ height: '1px', width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#000' }}></div>
                   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                       <div style={{ fontSize: '12px', color: '#808080',fontFamily:'var(--font)' }}> <span style={{fontSize: '12px',fontFamily:'var(--font)',letterSpacing:'1px',color:'#ff7600'}}>DOI:</span>{article.doi} </div>
                       <div  style={{ fontSize: '13px', color: '#fff', padding: '5px', backgroundColor: 'rgb(218, 218, 218)', fontWeight: 'bold', letterSpacing: '1px', borderRadius: "5px",fontFamily:'var(--font)' }}> <FilePdfOutlined /> PDF </div>
                   </div>
               </div> :<div id="articlehead2" style={{ marginRight: '10px', padding: '5px' }}>
                   <div style={{ height: '1px', width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#000' }}></div>
                   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                       <div style={{ fontSize: '12px', color: '#808080',fontFamily:'var(--font)' }}> <span style={{fontSize: '12px',fontFamily:'var(--font)',letterSpacing:'1px',color:'#ff7600'}}>DOI:</span>{article.doi} </div>
                       {loadings ? <div style={{ fontSize: '20px',marginLeft:'20px' }}><LoadingOutlined style={{ color: 'red' }} /> </div> : <div style={{ fontSize: '20px',marginLeft:'20px' }}>{isFavorite ? <HeartFilled onClick={() => { addOrRemoveLibrary(0) }} style={{ color: 'red' }} /> : <HeartFilled onClick={() => { addOrRemoveLibrary(1) }} style={{ color: '#273746' }} />}</div>}
                       <RWebShare
                                data={{
                                    text: article.name,
                                    url: serverurl+"article/doi/"+article.doi,
                                    title: "Share",
                                }}
                                onClick={() => console.log("shared successfully!")}>
                       <ShareAltOutlined style={{ fontSize: '20px',marginLeft:'10px',marginTop:'4px' }} />
                       </RWebShare>
                       {article.isHavePdf ? <div onClick={goToReader} style={{ fontSize: '13px', color: '#fff', padding: '5px', backgroundColor: '#ff7600', fontWeight: 'bold', letterSpacing: '1px', borderRadius: "5px",fontFamily:'var(--font)' }}> <FilePdfOutlined /> PDF </div> : null}
                   </div>
               </div>}
             {isFetchingArticle?<div style={{ marginRight: '10px', padding: '5px' }}><Skeletonarticle/></div> :<>
             {!showpageheader?  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'fixed', bottom: '5px',right:'10px', backgroundColor: '#DBDCDD', height: '40px', width: '40px', borderRadius:'50%',paddingLeft: '7px', paddingRight: '7px' }}>
                       <div onClick={goTop} style={{ fontSize: '14px', letterSpacing: '1px',fontFamily:'var(--font)' }}><ArrowUpOutlined  style={{ color: '#ff7600' }} /></div>
               </div>:null}
             {article.abstractView?  <>
               <div style={{ fontSize: '15px', letterSpacing: '1px', marginLeft: '5px',fontFamily:'var(--font)',color:'#000' }}>Abstract</div>
               <div style={{ marginTop: '5px', padding: '7px' }}>
                   <div id="abstracthtml" style={{letterSpacing:'1px',color:'#273746',fontSize:'13px' }} dangerouslySetInnerHTML={{ __html: article.abstractView }} />
               </div>
               <div style={{ marginRight: '10px', padding: '5px' }}>
                   <div style={{ height: '1px', width: '100%', marginBottom: '2px', backgroundColor: '#E5E5E7' }}></div>
               </div>
               </>:null}
               {article.keywords.length > 0 ? <> <div style={{ fontSize: '15px', letterSpacing: '1px',marginLeft: '5px',fontFamily:'var(--font)',color:'#273746' }}>Keywords</div>
                   <div style={{ marginTop: '5px', marginRight: '10px', padding: '5px' }}>
                       <div style={{fontFamily:'var(--font)',color: '#ff7600',fontSize:'13px'}}>{article.keywords.toString().replaceAll(",", ", ")}</div>
                   </div>
                   <div style={{ marginRight: '10px', padding: '5px' }}>
                       <div style={{ height: '1px', width: '100%', marginTop: '10px', marginBottom: '10px', backgroundColor: '#E5E5E7' }}></div>
                   </div>
               </> : null}
               <div style={{ marginTop: '5px', padding: '10px',  }}>
                  <div id="articlehtml"   onClick={contentClickHandler} style={{ overflowX: 'auto',letterSpacing:'1px',color:'#273746',fontSize:'13px'  }} dangerouslySetInnerHTML={{ __html: article.htmlView }} />
               </div>
               <div style={{backgroundColor: "#F6F6F6",margin:'10px',padding:'10px',textAlign:"left",marginBottom: '50px',wordWrap:'break-word',overflowWrap:'break-word',}}>
				 <span style={{fontSize:'15px',fontWeight:'bold'}}>License : </span> <div dangerouslySetInnerHTML={{ __html: article.License }}/> </div>
               </>}
           </div>
           </Col>

       </div>

    );
}


export default Artical;